/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState } from "react";
import RockitLink from "../link/rockit-link";
import { NavItem } from "./NavItem";
import { graphql, useStaticQuery } from "gatsby";
import MenuButton from "../buttons/menu-button.component";
export interface navProps {
  sx?: any; //we expect a sx prop but never use it below
  className?: string; //we expect a className prop but never define one when the component is called
}
export const Nav: React.FC<navProps> = ({ className }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  const { contentfulNavigation } = useStaticQuery(query);
  const navItems: NavItem[] = contentfulNavigation.navItems;

  const nav = navItems.map((nav, i) => {
    return (
      <li
        key={i}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pr: [0],
          fontSize: [5, 3],
          fontWeight: "light",
          pt: [2, 0, 0],
          color: "white",
        }}
      >
        <RockitLink
          to={nav.link}
          //state={{ ...menuItem.otherData }}
          activeClassName="active"
          sx={{
            color: "inherit",

            "&.active": {
              fontWeight: "bold",
            },
            variant: "",
          }}
        >
          {nav.label}
        </RockitLink>
      </li>
    );
  });

  return (
    <div sx={{ width: ["auto", "50%"] }}>
      <div sx={{ display: ["inline", "none"] }}>
        <MenuButton
          open={isExpanded}
          onPress={() => toggleExpansion(!isExpanded)}
        />
      </div>
      <div
        sx={{
          width: "100%",
          display: [isExpanded ? "block" : "none", "flex"],
          //position: "relative",
        }}
      >
        <nav
          sx={{
            width: "100%",
            position: ["absolute", "relative"],
            left: "0px",
            top: ["100px", "0px", "0px"], //height of mobile header
            bg: ["supportingALight", "inherit", "inherit"],
            py: [3, 0, 0],
            //display: "flex",
            //justifyContent: ["center", "flex-end"],
            //alignItems: ["flex-start"],
          }}
        >
          <ul
            sx={{
              width: ["100%"],
              display: "flex",
              alignItems: "center",
              listStyleType: "none",
              flexWrap: "wrap",
              p: 0,
              m: 0,
              flexDirection: ["column", "row"],
              justifyContent: ["space-around", "space-between"],
            }}
            className={className}
          >
            {nav}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;

const query = graphql`
  query {
    contentfulNavigation(type: { eq: "MainMenu" }) {
      navItems {
        label
        link
        highlight
      }
    }
  }
`;
