/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const Logo: React.FC = () => {
  return (
    <svg viewBox="0 0 319 277" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M159.837 140.611L311.066 274.1L159.837 237.115L7.51025 274.1L159.837 140.611Z"
        fill="#FFB9A1"
      />
      <path
        d="M213.714 94.0743L318.531 276.794L162.286 139.142L213.714 94.0743Z"
        fill="#FE926F"
      />
      <path
        d="M159.837 0.510254L212.245 92.115L0.16333 276.794L159.837 0.510254Z"
        fill="#FE6C3C"
      />
    </svg>
  );
};

export default Logo;
