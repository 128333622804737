/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import ContactForm from "../forms/contact-form.component";
import { Icon } from "@iconify/react";
import emailIcon from "@iconify/icons-carbon/email";
export const Contact: React.FC = () => {
  return (
    <div
      id="contact"
      sx={{
        variant: "layout.narrow",
        display: "flex",
        flexDirection: "column",
        //alignItems: "center",
      }}
    >
      <Icon
        icon={emailIcon}
        sx={{ alignSelf: "center", fontSize: 6, color: "primary" }}
      />
      <h2
        sx={{
          fontSize: [6, 6, 6],
          fontWeight: "body",
          lineHeight: "60px",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Start your project today
      </h2>
      <div
        sx={{ fontSize: 3, fontWeight: "light", textAlign: "center", pb: 3 }}
      >
        Let RockitWeb build your site while you build your business.
      </div>

      <div sx={{ display: "flex" }}>
        <div sx={{ width: "100%" }}>
          <ContactForm sx={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
