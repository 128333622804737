/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import CompanyName from "../site-elements/company-name.component";
import { useStaticQuery, graphql, Link } from "gatsby";

export const Footer: React.FC = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();

  const { contentfulSiteSettings } = useStaticQuery(query);

  const { abn } = contentfulSiteSettings;

  return (
    <footer sx={{ bg: "supportingADark" }}>
      <div sx={{ variant: "layout.box" }}>
        <div
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CompanyName includeLogo={true} direction="row" />
          <div
            sx={{ color: "white" }}
          >{`© ${currentYear} All rights reserved. RockitWeb. ABN: ${abn}`}</div>
          <div sx={{ color: "white" }}>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
const query = graphql`
  query {
    contentfulSiteSettings {
      title
      contactEmail
      contactPhone
      abn
    }
  }
`;
