/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useEffect, useState } from "react";

import Nav from "../nav/nav.component";
import CompanyName from "../site-elements/company-name.component";
import { Link } from "gatsby";
import { Icon } from "@iconify/react";
import phoneAlt from "@iconify/icons-fa-solid/phone-alt";
export const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 45;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <header
      sx={{
        width: "100%",
        zIndex: "999",
        position: "sticky",
        top: "0",
        bg: scrolled ? "supportingA" : "supportingADark",

        //height: ["60px", "80px", "80px"],
      }}
    >
      <div
        sx={{
          color: "white",
          width: "100%",
          bg: !scrolled ? "supportingA" : "supportingADark",
        }}
      >
        <div
          sx={{
            display: "flex",
            variant: "layout.box.noPadding",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Icon icon={phoneAlt} sx={{ fontSize: 2, color: "white" }} />
          <div
            sx={{
              ml: 2,
              px: [2, 0, 0],
              fontSize: 3,
              fontWeight: "thin",
            }}
          >
            <a href="tel:0418308859">0418308859</a>
          </div>
        </div>
      </div>
      <div
        sx={{
          height: "100%",
          variant: "layout.box.noPadding",
          px: [2, 0, 0],
        }}
      >
        <div
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 3,
          }}
        >
          <Link to="/">
            <CompanyName includeLogo={true} />
          </Link>
          <Nav />
        </div>
      </div>
    </header>
  );
};

export default Header;
