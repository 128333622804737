/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Header from "../header/header.component";
import Contact from "../contact/contact.component";
import Footer from "../footer/footer.component";
import "@fontsource/roboto/latin-100.css";
import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-700.css";

type LayoutProps = { hideContact?: boolean };
export const Layout: React.FC<LayoutProps> = ({ children, hideContact }) => {
  return (
    <div
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header></Header>
      {children}
      {!hideContact && <Contact />}
      <Footer />
    </div>
  );
};

export default Layout;
