/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Logo from "../design-elements/logo";

export type CompanyNameProps = {
  includeLogo: boolean;
  direction?: "column" | "row";
};
export const CompanyName: React.FC<CompanyNameProps> = ({
  includeLogo,
  direction,
}) => {
  const logo = includeLogo ? <Logo /> : "";
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: direction ? direction : "row",
      }}
    >
      <div sx={{ width: ["35px", , "50px"], height: ["35px", , "50px"] }}>
        {logo}
      </div>
      <div
        sx={{
          //lineHeight: 1,
          display: "flex",
          alignItems: "center",
          color: "white",
          fontFamily: "heading",
          fontSize: [5, 5, 5],
          ml: 2,
        }}
      >
        <div sx={{ fontWeight: "bold", p: { margin: 0, padding: 0 } }}>
          Rockit
        </div>
        <div sx={{ fontWeight: "light", p: { margin: 0, padding: 0 } }}>
          Web
        </div>
      </div>
    </div>
  );
};

export default CompanyName;
