/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Icon } from "@iconify/react";
import menuIcon from "@iconify/icons-codicon/menu";
import chromeClose from "@iconify/icons-codicon/chrome-close";
export type MenuButtonProps = { onPress; open: boolean };
export const MenuButton: React.FC<MenuButtonProps> = ({ onPress, open }) => {
  return (
    <button
      aria-label="Mobile Menu Button"
      onClick={onPress}
      sx={{
        border: "none",
        bg: "transparent",
        backgroundImage: "none",
        padding: 0,
      }}
    >
      <Icon
        icon={open ? chromeClose : menuIcon}
        sx={{ fontSize: 5, fontWeight: "bold", color: "primary" }}
      />
    </button>
  );
};

export default MenuButton;
