/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby";

import scrollToElement from "scroll-to-element";

export const isBrowser = typeof window !== "undefined";

function linkClick(to: string) {
  if (isBrowser && to.includes("#")) {
    const [anchorPath, anchor] = to.split("#");

    if (window.location.pathname === anchorPath) {
      //e.preventDefault()
      scrollToElement(`#${anchor}`, {
        offset: -100,
        align: "top",
        duration: 1200,
      });
    }
  }
}

export type RockitLinkProps = {
  to: string;
  title?: string;
  state?: any;
  activeClassName?: string;

  sx?: any; //we expect a sx prop but never use it below
  className?: string; //we expect a className prop but never define one when the component is called
};
export const RockitLink: React.FC<RockitLinkProps> = ({
  to,
  title,
  state,
  activeClassName,
  children,
  className,
}) => {
  return (
    <Link
      to={to}
      className={className}
      activeClassName={activeClassName}
      title={title}
      state={state}
      onClick={() => {
        linkClick(to);
      }}
    >
      {children}
    </Link>
  );
};

export default RockitLink;
